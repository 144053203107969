.flex,
.flex-block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  font-size: 15px;
}

.flex-xc {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
}

.flex-yc {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

#shake {
  color: #2071ff;
}

.shake {
  animation-name: font-size-pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}

@keyframes font-size-pulse {

  0%,
  100% {
    font-size: 1em;
  }

  50% {
    font-size: 0.8em;
  }
}

.step1-title {
  line-height: 1em;
  height: 1em;
  padding: 1em 0;
  font-size: 18px;
  margin-right: 0.5em;
  margin-bottom: 0.75em;
}

.step1-title>* {
  border-bottom: 0.02667rem solid #e1e1e1;
  padding-bottom: 1em;
}

.list-component {
  width: 100%;
  height: 25px;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  display: inline-block;
  text-align: left !important;
}

.list-header {
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.list-title {
  font-size: 14px;
  color: #333;
}

.list-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #ccc;
}

.list-body {
  display: none;
  position: absolute;
  top: 25px;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 4px;
  z-index: 1;
}

.list-body-show {
  display: block !important;
}

.list-item {
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.list-item:hover {
  background-color: #ddd;
}

.list-item.active {
  background-color: #ddd;
}

.step1-col0 {
  width: 50%;
  position: relative;
}

.step1-col0-right {
  padding-left: 32px;
}

.step1-col1 {
  width: 50%;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
}

.step1-col2 {
  width: 50%;
  height: 40px;
  line-height: 40px;
  text-align: right;
}

.step1-col3 {
  width: 33% !important;
  font-size: 0.875em;
}

.step1-col3-gray {
  color: rgb(204, 204, 204);
  text-decoration: line-through;
  font-weight: normal;
}

.step1-decoration {
  text-decoration: line-through;
  font-weight: normal;
  color: #ccc;
}

.step1-textright {
  text-align: right;
}

.step1-hr {
  border-bottom: 0.02667rem solid #e1e1e1;
}

.step1-font-large {
  font-size: 18px;
}

.step1-font-large>* {
  height: 2em;
  line-height: 2em;
}

.step1-font-tips {
  font-weight: bold;
}

.step1-font-red {
  color: red;
}

.step1-include {
  padding: 12px;
  border: solid 2px #e1e1e1;
  margin: 12px 0;
}

.step1-include-row {
  padding: 5px 0;
}

.step1-include-row>span {
  padding: 0 14px 0 24px;
}

.radio-label {
  padding: 0;
  pointer-events: none;
  cursor: pointer;
  line-height: 1.2;
  margin: 0;
  border: 0;
  vertical-align: baseline;
}

.radio-label.checked .radio {
  // top: 0.5rem;
  border-color: #0f866c;
}

.radio {
  align-items: center;
  background-color: #fff;
  border: 2px solid #a2acb3;
  border-radius: 50%;
  display: flex;
  height: 15px;
  justify-content: center;
  left: 0%;
  position: absolute;
  top: 25%;
  width: 15px;
}

.radio-label .radio::after {
  background: #0f866c;
  display: none;
  border-radius: 50%;
  content: "";
  height: 11px;
  width: 11px;
}

.radio-label.checked .radio::after {
  background: #0f866c;
  display: inline-block;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 720px;
  width: 96%;
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  transform: translate(-50%, -55%);
}

.container .line {
  width: 2px;
  height: 20px;
  background: #fff;
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin-left: -1px;
}

.container .close-btn {
  width: 36px;
  height: 36px;
  color: #fff;
  border-radius: 100%;
  background: rgba(000, 000, 000, 0.5);
  border: 2px solid #fff;
  box-sizing: border-box;
  position: absolute;
  bottom: -56px;
  left: 50%;
  transform: translateX(-50%);

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64%;
  }
}

.container .close-btn:hover {
  cursor: pointer;
  background: rgba(000, 000, 000, 0.1);
}

.popup .mask {
  width: 100%;
  height: 100%;
  background: rgba(000, 000, 000, 0.7);
}

.package-info {
  margin-bottom: 14px;
  font-size: 14px;
  color: #333;
  line-height: 20px;

  span {
    float: right;
    text-decoration: line-through;
  }
}

.pay-btn {
  // position: absolute;
  // bottom: 5%;
  // left: 50%;
  // display: inline-block;
  padding: 10px 48px;
  font-size: 14px;
  white-space: nowrap;
  color: #fff;
  background: #fea01a;
  border-radius: 8px;
  // transform: translateX(-50%);
  text-align: center;
  width: 40%;
  margin: 10px auto 0 auto;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  .pay-price {
    margin: 0 8px;
    font-weight: bold;
  }
}

.content-box3 {
  overflow-y: auto;
  max-height: 72vh;
}

.crypto-tag {
  display: block;
  margin: 0 auto;
  width: 100px;
}

.crypto-qrcode {
  display: block;
  margin: 0 auto;
  width: 120px !important;
  height: 120px !important;
}

.crypto-box {
  margin: 16px;
  position: relative;
}

.crypto-text {
  margin-bottom: 4px;
  font-size: 12px;
  color: #b3b3b3;
  font-variant-numeric: tabular-nums;
}

.crypto-label {
  // margin-bottom: 4px;
  font-size: 13px;
  color: #333333;
}

span.crypto-label {
  // overflow: hidden;
  display: inline-block;
  margin-bottom: 8px;
  // max-width: 64%;
  vertical-align: middle;
  // white-space: nowrap;
  // text-overflow: ellipsis;
}

.crypto-btn {
  // margin-bottom: 8px;
  // margin-left: 16px;
  top: 0;
  right: 1em;
  position: absolute;
  border: .5px solid #333333;
  padding: 0 16px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  vertical-align: middle;
  color: #333333;
  background: inherit;
  border-radius: 16px;
  cursor: pointer;
}

.crypto-line {
  margin-top: 8px;
  width: 100%;
  height: 0.5px;
  background-color: #e1e1e1;
}

.text-center {
  text-align: center;
}

.tip {
  margin-top: 8px;
  font-size: 12px;
  color: red;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .content-box {
    padding: 8px 24px;
  }
}

.choose-rolls {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e1e1e1;

}

.title {
  margin-bottom: 8px;
  font-size: 14px;
  color: #888888;
}

.box {
  overflow-y: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
  }
}

.item {
  position: relative;
  display: inline-block;
  margin-right: 16px;
  width: 160px;
  height: 88px;
  font-size: 16px;
  color: #ffffff;
  background: #cccccc;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: #708bf7;
    background: linear-gradient(#708bf7, #52a1f4);
  }

  &.active {
    background: #708bf7;
    background: linear-gradient(#708bf7, #52a1f4);
  }

  span {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  div {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  p {
    position: absolute;
    bottom: 16px;
    width: 100%;
    font-size: 12px;
    text-align: center;
  }
}

.no-rolls {
  height: 90px;
  line-height: 85px;
  text-align: center;
  font-size: 14px;
  color: #888;
}

.amount {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  text-align: right;
}

.amount>span {
  margin-left: 4px;
  font-size: 16px;
  color: #ff4c4c;
}

.tips {
  margin-top: 8px;
  text-align: right;
  font-size: 12px;
  color: #888;
}

.choose-type {
  // display: table-cell;
  width: 100%;
  text-align: center;
}

.type-item {
  // float: left;
  margin: 0 !important;
  width: 65% !important;
  min-width: 80px;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
}

.img {
  position: relative;
  margin: 0 auto;
  // margin-bottom: 8px;
  padding: 4px 0;
  line-height: 32px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 8px;
}

.img>div {
  position: absolute;
  top: -15.7px;
  right: 34px;
  width: 100px;
  height: 48px;
  font-size: 18px;
  line-height: 40px;
  color: #ffffff;
  transform: scale(0.4);
}

.img>img {
  width: 80px;
  vertical-align: middle;
}

.text {
  margin: 0 auto;
  width: inherit;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #333;
}

@media screen and (min-width: 769px) {
  .type-item {
    margin: 0 55px;
    width: 100px;
  }
}

.content-box2 {
  padding: 36px 0;
}

.order-title {
  margin: 0 auto;
  margin-bottom: 40px;
  font-size: 16px;
  color: #333;
  text-align: center;
}

.order-info {
  height: 40px;
  font-size: 14px;
  color: #666;
  line-height: 20px;

  span:last-child {
    float: right;
  }
}

.price {
  font-size: 16px;
  font-weight: bold;
  color: #ff4c4c;
}

.paypal-button {
  margin: 50px auto 20px auto;
  width: 240px;
  height: 45px;
}

.confirm-pay-btn {
  margin: 52.5px auto 22.5px auto;
  width: 240px;
  height: 40px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #fff;
  line-height: 40px;
  background: #fea01a;
  border-radius: 30px;
}

.confirm-pay-btn:hover {
  cursor: pointer;
  opacity: 0.8;
}

.last-confirm-pay-btn {
  margin-top: 0;
  color: #2071ff;
}

.back {
  margin-top: 16px;
  font-size: 14px;
  color: #666;
  text-align: center;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

@media screen and (min-width: 768px) {
  .content-box2 {
    padding: 36px 72px;
  }
}

@media screen and (min-width: 1600px) {
  .content-box2 {
    padding: 36px 120px;
  }
}

@media screen and (max-width: 767px) {

  .flex,
  .flex-block {
    font-size: 14px;
  }

  .flex-block {
    flex-direction: column;
  }

  .flex-grow {
    width: 100%;
  }

  .step1-col0-right {
    padding-left: 0;
  }

  .step1-col2 {
    text-align: right;
  }

  .pay-btn {
    position: relative;
  }

  .step1-title {
    line-height: 0.5em;
    height: 0.5em;
    padding: 0.75em 0;
    font-size: 16px;
    margin-right: 0.25em;
    margin-bottom: 0.375em;
  }

  .step1-title>* {
    padding-bottom: 0.75em;
  }

  .step1-col1,
  .step1-col2 {
    height: 30px;
    line-height: 30px;
  }

  .step1-font-large {
    font-size: 16px;
  }

}